import React, { useState, useEffect } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaLinkedinIn } from "@react-icons/all-files/fa/FaLinkedinIn";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";

const Checker = ({ share, hindi }) => {
  if (share) return <ShareSocial hindi={hindi} />;
  else return <SocialConnect hindi={hindi} />;
};

const ShareSocial = ({ hindi }) => {
  const [loc, setPageURL] = useState(0);

  useEffect(() => {
    setPageURL(window.location.href);
  });
  // let loc = window.location.href;

  return (
    <div className="social-connect">
      <h4>{hindi ? "इसे सोशल पर शेयर करें" : "Share this on Social"}</h4>
      <Link
        to={"https://www.facebook.com/sharer.php?u=" + loc}
        target="blank"
        className="fb"
      >
        <FaFacebookF />
      </Link>
      <Link
        to={"https://twitter.com/intent/tweet?url=" + loc}
        target="blank"
        className="twi"
      >
        <FaTwitter />
      </Link>
      <Link
        to={"https://www.linkedin.com/shareArticle?mini=true&url=" + loc}
        target="blank"
        className="lind"
      >
        <FaLinkedinIn />
      </Link>
      <Link
        target="blank"
        to={"https://wa.me?text=" + encodeURIComponent(loc)}
        className="whats"
      >
        <FaWhatsapp />
      </Link>
    </div>
  );
};

const SocialConnect = () => (
  <StaticQuery
    query={graphql`
      {
        strapiSocialMedia {
          instagram
          facebook
          twitter
          whatsapp
          linkedin
        }
      }
    `}
    render={(data) => {
      let s = data.strapiSocialMedia;
      return (
        <div className="social-connect">
          <h4>Connect Us on Social</h4>
          <Link to={s.facebook} className="fb">
            <FaFacebookF />
          </Link>
          <Link to={s.twitter} className="twi">
            <FaTwitter />
          </Link>
          <Link to={s.linkedin} className="lind">
            <FaLinkedinIn />
          </Link>
          <Link to={s.instagram} className="inst">
            <FaInstagram />
          </Link>
          {s.whatsapp.length > 0 && (
            <Link
              to={
                "whatsapp://send?abid=" + s.whatsapp + "&text=Hello%2C%20World!"
              }
              className="whats"
            >
              <FaWhatsapp />
            </Link>
          )}
        </div>
      );
    }}
  ></StaticQuery>
);

export default Checker;
