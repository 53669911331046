import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SocialConnect from "../components/ConnectSocial";
import DownloadLink from "react-download-link";
import { GrDocumentPdf } from "@react-icons/all-files/gr/GrDocumentPdf";

const About = ({ data }) => (
  <Layout title={"Resourses of Pest Control"}>
    <div className="about">
      <div className="hero2">
        <h1>Resources</h1>
      </div>

      <div className="ssbody wrapper_max">
        <div className="content">
          {data.strapiResources.Media.map((v, k) => (
            <div className="resources" key={k}>
              <div className="one">
                <a href={v.upload?.localFile.url} download>
                  <GrDocumentPdf className="icons" />
                </a>
              </div>
              <div className="sep">{` `}</div>
              <div className="two">{v.description}</div>
            </div>
          ))}

          <SocialConnect />
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  {
    strapiResources {
      Media {
        description
        upload {
          localFile {
            url
          }
        }
      }
    }
  }
`;

export default About;
